body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--main-font-size);
  /* Makes sure that we don't trigger text selection on iOS Mobile */
  -webkit-touch-callout: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --z-index-digitalhuman: -999;
  --z-index-loadingcomponent: -998;
  --z-index-modal: 999;
  --z-index-mobileburger: 998;
  --z-index-mobilemenu: 997;
}

* {
  box-sizing: border-box;
}

/* Styling the scrollbar */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
}

/* Stops iOS Mobile from zooming in on input elements when font-size < 16px */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: #eee;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
