.MobileTypeAhead {
  width: 100%;
  min-height: calc(80px * var(--spacebar-scaling-factor));
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid var(--primary-color-opaque);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  transition: opacity 1s;
  flex-direction: column;
}
.MobileTypeAheadWrapper {
  width: 100%;
  margin: 0 auto;
}
.MobileTypeAheadWrapper.hidden {
  opacity: 0;
}

.MobileTypeAhead span {
  color: var(--primary-color);
  font-size: 14px;
  text-align: center;
}

@media (max-width: 767px) {
}

.MobileTypeAheadInputWrapper {
  width: 60%;
  height: 100%;
  display: flex;
}

.MobileTypeAheadInput {
  width: 100%;
  height: 30px;
}

.MobileTypeAheadInput:focus {
  outline: solid var(--primary-color) thin;
}

.MobileTypeAheadResultListLi {
  padding: 4px;
  border: var(--primary-color) solid 1px;
  background-color: rgba(255, 255, 255, 0.8);
}
.MobileTypeAheadResultList {
  text-align: center;
  list-style-type: none;
  padding-left: 0px;
  width: 60%;
  color: var(--primary-color);
  max-height: 15vh;
  overflow-y: scroll;
  --webkit-overflow-scrolling: touch;
}
@media (max-height: 300px) {
  .MobileTypeAheadResultList {
    max-height: 25vh;
  }
}
.MobileTypeAheadResultListWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileTypeAheadButton {
  background-color: var(--primary-color);
  color: white;
}
.MobileTypeAheadHeader {
  width: 60%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.MobileTypeAheadSendButton {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  width: 30%;
}
.MobileTypeAheadSendButton span {
  color: white;
  text-align: center;
}

.MobileTypeAheadSendButton:hover {
  background-color: var(--primary-color-lighter);
}
