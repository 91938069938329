:root {
  --main-font-size: 16px;
  --spacebar-scaling-factor: 0.8;

  --primary-color: rgba(70, 136, 149, 1);
  --primary-color-opaque: rgba(70, 136, 149, 0.8);
  --primary-color-lighter: rgb(91, 136, 145, 1);
  --primary-color-hex: #468895;

  --secondary-color: rgba(87, 95, 114, 1);
  --secondary-color-opaque: rgba(87, 95, 114, 0.8);
  --secondary-color-lighter: rgba(122, 143, 192, 1);
  --secondary-color-hex: #575F72;

  --text-dark-grey: rgba(51, 51, 51, 1);

  --background-color-modal: rgba(255, 255, 255, 1);
  --spinner-color: rgba(255, 255, 255, 1);

  /* First one must be hex, to make the SVG change color as well. */
  --mobile-bars-background: var(--primary-color-hex);
  --mobile-bars-top-border: rgba(0, 0, 0, 1);
  --mobile-bars-color: rgba(255, 255, 255, 1);
  --mobile-text: rgba(255, 255, 255, 1);

  --mobile-record-start-color: rgba(255, 255, 255, 1);
  --mobile-record-tap-color: rgba(220, 220, 220, 1);
  --mobile-record-stop-color: rgba(255, 0, 0, 1);

  --mobile-actions-background: rgba(216, 216, 216, 0.85);
  --mobile-actions-background-active: var(--primary-color-lighter);
  --mobile-actions-shadow: rgba(33, 33, 33, 0.5);

  /* Below must be hex, to make the SVGs change color. */
  --mobile-actions-link-icon: var(--primary-color-hex);
}
