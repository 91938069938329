.AnswerButtonsGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: stretch;
  justify-items: center;
  margin: 0 1rem 1rem 1rem;
}

.AnswerButtonsGroupAnswerButtons {
  border-radius: 25px;
  background: var(--mobile-text);
  color: var(--primary-color);
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
