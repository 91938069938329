.TypeAhead {
  width: 100%;
  min-height: calc(80px * var(--spacebar-scaling-factor));
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid var(--primary-color-opaque);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  transition: opacity 1s;
  flex-direction: column;
}
.TypeAheadWrapper {
  width: 100%;
  max-width: calc(600px * var(--spacebar-scaling-factor));
  margin: 0 auto;
}
.TypeAheadWrapper.hidden {
  opacity: 0;
}

.TypeAhead span {
  color: var(--primary-color);
  font-size: 14px;
  text-align: center;
}

@media (max-width: 767px) {
  .TypeAheadWrapper {
    width: 80%;
    min-height: 60px;
  }

  .TypeAheadWrapper span {
    font-size: 12px;
  }
}

.TypeAheadInputWrapper {
  width: 60%;
  height: 100%;
  display: flex;
}

.TypeAheadInput {
  width: 100%;
  height: 30px;
}

.TypeAheadInput:focus {
  outline: solid var(--primary-color) thin;
}

.TypeAheadResultListLi {
  padding: 4px;
  border: var(--primary-color) solid 1px;
  background-color: rgba(255, 255, 255, 0.8);
}
.TypeAheadResultList {
  text-align: center;
  list-style-type: none;
  padding-left: 0px;
  width: 60%;
  color: var(--primary-color);
  max-height: 30vh;
  overflow-y: scroll;
}
.TypeAheadResultListWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TypeAheadButton {
  background-color: var(--primary-color);
  color: white;
}
.TypeAheadHeader {
  width: 60%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.TypeAheadSendButton {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  width: 30%;
}
.TypeAheadSendButton span {
  color: white;
  text-align: center;
}

.TypeAheadSendButton:hover {
  background-color: var(--primary-color-lighter);
}
