.MobileQuestion {
  color: var(--mobile-text);
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}
.MobileQuestionText {
  font-weight: 800;
  padding: 0 2rem 0 2rem;
  margin-top: 1rem;
  font-size: 16px;
}
.MobileQuestionIcon {
  position: absolute;
  /* margin-top: 1rem; */
  vertical-align: middle;
}
