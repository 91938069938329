.AnswerContainer {
  width: 25%;
  background-color: var(--primary-color);
  color: white;
}

@media (max-width: 1000px) {
  .AnswerContainer {
    width: 50%;
  }
}
