.MobileSuggestedLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: var(--mobile-actions-background);
  box-shadow: 0 2px 7px 2px var(--mobile-actions-shadow);
  border-radius: 22.5px;
  margin: 5px 10px;
  padding: 10px 0px;
  color: var(--text-dark-grey);
  text-align: right;
}

.MobileSuggestedLink.pulse {
  animation: pulse 2s 1.3s ease-in-out infinite forwards;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.MobileSuggestedLinkIcon {
  box-sizing: border-box;
  height: 16px;
  margin: 0px 5px 0px 10px;
  fill: var(--mobile-actions-link-icon);
}

.MobileSuggestedLink span {
  font-size: 16px;
  margin: 0px 22px 0px 22px;
  cursor: pointer;
}

.MobileSuggestedLink:active {
  background: var(--mobile-actions-background-active);
}
